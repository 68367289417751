import React from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  Button,
  Grid,
  Box,
  Container,
  Card,
  CardActionArea,
} from "@material-ui/core"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { withWidth } from "@material-ui/core"
import { useSelector } from "react-redux"
import LocalizedLink from "../../components/LocalizedLink"

const useStyles = makeStyles(theme => ({
  cardLeft: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(1.5),
    },
  },
  cardMiddle: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  cardRight: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(1.5),
    },
  },
  sectionTitle: {
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(11),
    },
  },
  sectionTitleBlog: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
    },
  },
  cardsWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(12.5),
    },
  },
  cardsWrapperBlog: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(15),
    },
  },
  cardTitle: {
    paddingTop: theme.spacing(2),
  },
  cardBody: {
    paddingTop: theme.spacing(2),
  },
  bgImage: {
    backgroundSize: "contain",
    backgroundPosition: "0 100%",
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
  
}))

const Cards = ({
  title,
  tourPageMainData,
  dmcPageMainData,
  investPageMainData,
  isBlog,
}) => {
  const translations = useSelector(state => state.locale.localeObj.pageData)
  const classes = useStyles()

  const { homeImg, dmcImg, investImg } = useStaticQuery(
    graphql`
      query ImageQuery {
        homeImg: file(name: { eq: "HomeToursCropped" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dmcImg: file(name: { eq: "DMCHomeCropped" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        investImg: file(name: { eq: "InvestHomeCropped" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const cardsData = [
    {
      title: tourPageMainData.pageTitle,
      body: tourPageMainData.pageSubTitle,
      bgColor: "#FF9A8B",
      bgImg: homeImg,
      overline: translations.tour,
      link: "/tours",
    },
    {
      title: dmcPageMainData.topSectionPageTitle,
      body: dmcPageMainData.topSectionDescription.topSectionDescription,
      bgColor: "#66B7BA",
      bgImg: dmcImg,
      overline: translations.dmc,
      link: "/dmc",
    },
    {
      title: investPageMainData.pageTitle,
      body: investPageMainData.pageDescription.pageDescription,
      bgColor: "#FAD578",
      bgImg: investImg,
      overline: translations.invest,
      link: "/invest",
    },
  ]

  // Crude hack to add proper grid spacing because of MUI Grid negative margin issue
  // See: https://material-ui.com/components/grid/#negative-margin

  const getCardPadding = order => {
    switch (order) {
      case 0:
        return classes.cardLeft
      case 1:
        return classes.cardMiddle
      case 2:
        return classes.cardRight
      default:
        return ""
    }
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography
            variant={isBlog ? "h2" : "h1"}
            className={isBlog ? classes.sectionTitleBlog : classes.sectionTitle}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          className={isBlog ? classes.cardsWrapperBlog : classes.cardsWrapper}
        >
          {cardsData.map(
            ({ title, body, bgColor, bgImg, overline, link }, index) => (
              <Grid
                item
                xs={12}
                md={4}
                key={title}
                className={getCardPadding(index)}
              >
                <Card
                  style={{
                    backgroundColor: bgColor,
                    height: 368,
                  }}
                  elevation={0}
                >
                  <CardActionArea
                    style={{ height: "100%" }}
                    component={LocalizedLink}
                    to={link}
                  >
                    <Box display="flex" flexDirection="column" height={1}>
                      <Box p={3}>
                        <Box
                          clone
                          py={0.5}
                          px={1.25}
                          border={1}
                          borderColor="rgba(0,0,0,0.1)"
                          display="inline"
                        >
                          <Typography variant="overline">{overline}</Typography>
                        </Box>
                        <Typography variant="h3" className={classes.cardTitle}>
                          {title}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.cardBody}
                        >
                          {body}
                        </Typography>
                      </Box>
                      <BackgroundImage
                        fluid={bgImg.childImageSharp.fluid}
                        className={classes.bgImage}
                      >
                        <Button
                          variant="outlined"
                          endIcon={
                            <div className={classes.arrow}>
                              <ArrowForward />
                            </div>
                          }
                          style={{ backgroundColor: bgColor }}
                        >
                          {translations?.learnMore}
                        </Button>
                      </BackgroundImage>
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default withWidth()(Cards)
